.container {
  position: absolute;
  display: flex;
  flex-direction: column;

  height: 100vh;


  top: 0;


  color: rgba(255, 255, 255, 0.7);




  padding: 25px;
}
.content {
  padding: 10px;

  padding-top: 50px;
}
.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;

  color: #FFFFFF;

  width: 240px;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}
.menu {
  position: relative;
  display: flex;

  margin: 0;
  margin-top: 25px;
  margin-bottom: 55px;

  color: rgba(255, 255, 255, 1);
  line-height: 1.0;

  text-align: center;

  border-radius: 10px;

  overflow-x: auto;
  font-size: 16px;
  font-weight: 600;

  justify-content: center;

  overflow-y: hidden;
  scroll-behavior: smooth;

  align-items: flex-start;

  flex-direction: column;
  div {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    margin-top: 20px;
    line-height: 1.2;
    padding: 0;
    border-radius: 15px;
    white-space: nowrap;
    transition: 350ms;
    transition: .3s ease-in-out,box-shadow .3s ease-in-out;

    cursor: pointer;


  }
  div:hover {
  transform: rotate(-1deg);
  }
}
.oth {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-top: 25px;
  .phone {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .address {
    margin-top: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;

    margin-top: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100vw;
  }

  .menu {
    align-items: center;
  }
  .oth {
    align-items: center;
  }
  .logo {
    align-items: center;
      width: unset;
  }
}
