.container {
  position: relative;
  margin: auto;
  color: #fff;
  background:#262626;
}
.content {
  position: relative;
  max-width: 1428px;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}
.section {
  .content{
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 625px;
    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      text-align: center;
      color: #fff;
      width: 100%;
    }
    .losses, .timer, .description {
      position: relative;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      margin-top: 25px;
    }
    .button {
      background-color: rgb(64, 201, 255);
      width: 100%;
      height: 48px;
      color: rgb(29, 29, 29);
      font-weight: 600;
      padding: 12px;
      margin-top: 25px;
      border-radius: 15px;
      font-family: 'Inter';
      border: none;
    }
    .timer{
      display: grid;
      grid-template-columns: 180px 30px 180px 30px 180px;
      grid-template-rows: 2fr 50px;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      justify-items: stretch;
      align-items: stretch;
      width: 300px;
      text-align: center;
      .time {
        font-size: 148px;
        font-weight:bold;
      }
      .times {
        font-size: 28px;
        color: rgb(150, 150, 150);
      }
    }
    .description {
      font-size: 14px;
      font-family: 'Inter';
      color: rgb(142, 142, 142);
      text-align: left;
    }
    .losses {
      margin-top: 50px;
    }
    .losses b{
      color: rgb(64, 201, 255);
    }
  }
}
@media only screen and (max-width: 800px) {
  .section {
    .content{
      width: 100%;
      padding: 20px;
      .title {
        font-size: 24px;
      }
      .losses, .timer, .description {
        font-size: 12px;
      }
      .losses {
        margin-top: 25px;
      }

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .timercontainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        width: 270px;
      }
      .timer{
        display: grid;
        grid-template-columns: 80px 10px 80px 10px 80px;
        grid-template-rows: 2fr 20px;
        
        grid-column-gap:5px;
        grid-row-gap: 0px;
        justify-items: stretch;
        align-items: stretch;
        width: 300px;
        text-align: center;

        .time {
          font-size: 48px;
        }
        .times {
          font-size: 12px;
        }
      }



    }
  }
}
@media only screen and (max-width: 800px) {
  .container {
    margin-bottom: 50px;
  }
}
