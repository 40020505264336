.container {
  position: relative;
  margin: auto;
  color: #fff;
  background:#262626;
  margin-top: 100px;
}
.content {
  position: relative;
  max-width: 1428px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;

  color: #FFFFFF;
  
  .first::before {
    content: " ";

    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: -280px;
    bottom: 50px;
    height: 450px;
    width: 450px;
  }
  .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 200px;
    top: 160px;
    height: 34px;
    width: 34px;
  }
  .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0px;
    top: 0px;
    height: 160px;
    width: 160px;
  }
  .four::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0px;
    top: -50px;
    height: 61px;
    width: 160px;
  }
  .five::before {
    content: " ";

    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 80px;
    top: 150px;
    height: 36px;
    width: 36px;
  }
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
    max-width: 980px;
  }
}
.content {
  padding-top: 25px;
}
.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    transition: .2s ease-in-out,box-shadow .2s ease-in-out;
    cursor: pointer;
  }
  .logo:hover {
    transform: scale(1.1);
    color: rgb(64, 201, 255);
  }
.menu {
  position: relative;
  display: flex;

  margin: 0;
  margin-top: 25px;
  margin-bottom: 55px;

  color: rgba(255, 255, 255, 1);
  line-height: 1.0;

  text-align: center;

  border-radius: 10px;
  width: 100%;
  overflow-x: auto;
  font-size: 16px;
  font-weight: 600;

  justify-content: center;

  overflow-y: hidden;
  scroll-behavior: smooth;

  align-items: flex-start;

  flex-direction: row;

  div {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    margin: 20px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.2;
    padding: 0;
    border-radius: 15px;
    white-space: nowrap;
    transition: 350ms;
    transition: .3s ease-in-out,box-shadow .3s ease-in-out;

    cursor: pointer;

  }
  div:hover {
    transform: scale(1.05);
    color: rgb(64, 201, 255);
  }
}
.oth {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 25px;
  .phone {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: RGB(255, 255, 255);
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 5px;
    max-width: 380px;
  }
  .address {
    margin-top: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    margin-top: 0;
  }
}
@media only screen and (max-width: 800px) {
  .menu {
    margin-bottom: 0;
  }
  .content {
    background-image: unset;
    width: 100vw;
    transform: scale(0.9);
  }
  .section {
    h1 {
      font-size: 25px;
    }
    .first::before {
      background-image: unset;
    }
    .two::before {
      background-image: unset;
    }
    .three::before {
      background-image: unset;
    }
    .four::before {
      background-image: unset;
    }
    .five::before {
      background-image: unset;
    }
    .six::before {
      background-image: unset;
    }
    .seven::before {
      background-image: unset;
    }
  }
}
