html,
body {
  /*scroll-behavior: smooth;*/
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
* {-webkit-tap-highlight-color: rgba(0, 0, 0, 0)} 

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal;
}

::-webkit-scrollbar {
  height: 5px;
  width: 18px;
  background: rgba(0, 0, 0, 0);
  display: none;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}



/*
.MuiOutlinedInput-root{
  border-radius: 25px !important;
  border-color: none !important;
  border: 0  !important;
}

.MuiOutlinedInput-root:hover{
  border-color: none !important;
  border: 0  !important;

}

.MuiOutlinedInput-root input {
  border-radius: 25px !important;
  border-color: none !important;
  border: 0  !important;


}
*/