.container {
  position: relative;
  margin: auto;
  color: #262626;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.content {
  position: relative;
  max-width: 1428px;
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 55px;

  background-position: top;
  background-repeat: no-repeat;
  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 71px;
    text-align: center;
    color: #262626;   
  }
  .description{
    color: #262626;
    font-size: 18px;
    text-align: center;
    padding-left: 150px;
    padding-right: 150px;
  }
}
.section {
  margin: 0;
  margin-top: 75px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
  .item {
    position: relative;
    margin: 10px;
    display: flex;
    width: 305px;
    min-width: 305px;
    height: 270px;
    border-radius: 25px;
    padding-top: 150px;
    color:white;
    text-align: center;

    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.2);

    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #262626;
      padding: 50px;
      padding-top: 25px;
      width: 100%;
      .description {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;

	padding: 50px;
	width: 100%;
	padding-top: 5px;
	padding-left: 0;
	padding-right: 0;
      }
    }

  }

  .item:nth-child(2){
    background-color: rgb(41, 41, 41);
    color: rgb(255, 255, 255);
    .title {
      color: rgb(255, 255, 255);
      .description {
         color: rgb(255, 255, 255);
      }
    }
    .content {
      color: #E0E0E0;
    }
  }

  .item:nth-child(1) .first::before {
    content: " ";
    background-image: url('../../assets/images/Suitable/icons.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: calc(50% - 30px);
    top: 70px;
    height: 75px;
    width: 75px;
  }
  .item:nth-child(2) .first::before {
    content: " ";
    background-image: url('../../assets/images/Suitable/icons 2.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: calc(50% - 30px);
    top: 70px;
    height: 75px;
    width: 75px;
  }
  .item:nth-child(3) .first::before {
    content: " ";
    background-image: url('../../assets/images/Suitable/icons 3.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: calc(50% - 30px);
    top: 70px;
    height: 75px;
    width: 75px;;
  }
  .item:nth-child(4) .first::before {
    content: " ";
    background-image: url('../../assets/images/Suitable/icons 4.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: calc(50% - 30px);
    top: 70px;
    height: 75px;
    width: 75px;
  }
  .item:nth-child(5) .first::before {
    content: " ";
    background-image: url('../../assets/images/Suitable/icons 5.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: calc(50% - 30px);
    top: 70px;
    height: 75px;
    width: 75px;
  }
  .item:nth-child(6) .first::before {
    content: " ";
    background-image: url('../../assets/images/Suitable/icons 6.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: calc(50% - 30px);
    top: 70px;
    height: 75px;
    width: 75px;
  }
  .item:nth-child(1) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(2) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(3) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(4) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(5) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(6) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
}
@media only screen and (max-width: 1200px) {
  .content {
    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 33px;
      line-height: 44px;
      text-align: center;
      color: #262626;
    }
    .description{
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .content {
    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
      text-align: center;
      color: #262626;
    }
    .description{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
