.container {
  position: fixed;
  background: rgba(62, 62, 62, 0.91);
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  z-index: 3000;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
backdrop-filter: blur(5px);
}
.content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  max-width: 1428px;
  margin: auto;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;

  .logo {
    margin-top: -5px;
    margin-left: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;

    color: #FFFFFF;

    width: 240px;
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    transition: .2s ease-in-out,box-shadow .2s ease-in-out;
  }
  .logo:hover {
    transform: scale(1.1);
    color: rgb(64, 201, 255);
  }
}
.menu {
  position: relative;
  display: flex;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  line-height: 1.0;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  overflow-x: auto;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  overflow-y: hidden;
  scroll-behavior: smooth;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;

  div {
    background-color: rgba(0, 0, 0, 0);
    margin: 2px;
    line-height: 1.2;
    padding: 10px;
    padding-top: 0;
    border-radius: 15px;
    white-space: nowrap;
    transition: .2s ease-in-out,box-shadow .2s ease-in-out;
    cursor: pointer;
  }
  div:hover {
    transform: scale(1.05);
    color: rgb(64, 201, 255);
  }
}
.call {
  position: relative;
  color: #fff;
  width: 200px;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
       width: 240px;
    transition: .2s ease-in-out,box-shadow .2s ease-in-out;
  }
  .call:hover {
    transform: scale(1.1);
    color: rgb(64, 201, 255);
  }

@media only screen and (max-width: 1400px) {
  .content{
    .logo {
      font-size: 22px;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    background: rgba(62, 62, 62, 0.0);
  }
  .logo {
    opacity: 0;
  }
  .menu { 
    display: none;
  }
}
.Hamburger {
  position: relative;
  display: flex;
  margin: 0;
  margin-top: -15px;
  padding: 0;
  z-index: 2300;
  color: #fff;
  filter: invert(59%);
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}

@media only screen and (max-width: 900px) {
.container {
  background: rgba(62, 62, 62, 0);
backdrop-filter: unset;
  }
}

