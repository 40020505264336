.container {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.content {
  position: relative;
  max-width: 1628px;
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
    h1 {
	  font-family: "Inter";
	  font-style: normal;
	  font-weight: 700;
	  font-size: 50px;
	  line-height: 71px;
	  text-align: center;
	  color: rgb(38, 38, 38);
	}
}
.section {
  .title {
    color: rgba(0, 0, 0, 0.5);
    margin: 0px;
  }
  img {
    border-radius: 15px;
  }
  .content{
    display: flex;
    flex-wrap: nowrap;
    .row {
      width: calc(50vw - 100px);
      margin: 50px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .section {
    .title {
      display: block;
    }
    .content{
      flex-wrap: wrap;
      .row {
        width: calc(100vw - 50px);
        margin: 25px;
      }
    }
  }
}
.flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
  .item {  
    position: relative;
    transition: 300ms ease-in-out;
    font-size: 16px;
    color: rgba(2, 2, 2, 0.8);
    margin: 25px;
    width: 360px;
    margin: 64px;
    max-width: 100vw;
    max-height:  100vh;
    cursor: pointer;

    .hover {
      margin: auto;
      transition: 100ms;
    }
    .hover:hover {
      transform: scale(0.95);
    }
  }
  .item:hover {
    transform: scale(1.2);
  }
}
@media only screen and (max-width: 1200px) {
.content {
flex-direction: column;
}
  .flex {
    .item {
      margin: 0;
      margin-top: 25px;
      height: auto;
    }
  }
}
