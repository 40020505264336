

.content {
  position: fixed;
  display: block;
  margin:0;
  padding: 2px;
  font-size: 18px;
  color: rgb(39, 39, 39);
  text-align: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 1);
  text-align: left;
  border-radius: 25px;
  width: 640px;
  min-height: 380px;
  z-index: 3;
  transition: 5s;

  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 3px 14px 0 rgba(74, 58, 255, 0.16), 0 -2px 4px 0 rgba(20, 20, 43, 0.12), 0 12px 44px 0 rgba(20, 20, 43, 0.34);
  .header {
    height: 50px;
    text-align: center;
    h1 {
      cursor: default;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 44px;
      color: #262626;
    }
    h2 {
      cursor: default;
      font-weight: 400;
      font-size: 24px;
      line-height: 23px;
      text-align: center;
      color: #7A7A7A;
    }
    h3 {
      max-width: 373px;
      cursor: default;
      font-style: normal;
      font-weight: 700;
      font-size: 33px;
      line-height: 44px;
      color: #262626;
    }
  }
  .successfully {
    height: auto;
  }
  .form {
    position: relative;
    width: 325px;
    min-height: 200px;
  }
  .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: -100px;
    bottom: 0px;
    height: 200px;
    width: 245px;
  }
  .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0px;
    top: 125px;
    height: 172px;
    width: 65px;
  }
}
.margin {
  max-width: 28px;
  max-height: 28px;
}
.warning {
  color: rgba(59, 59, 59, 0.6);
  font-size: 14px;  
}
.error {
  color: rgba(255, 0, 0, 0.6);
  font-size: 14px;  
}
@media only screen and (max-width: 600px) {
  .container {
    text-align: left;
    margin-left: auto;
  }
  .content {
    width: 430px;
    max-width: 100vw;
    height: 430px;
    .header {
      height: 20px;
      h1 {
        font-size: 25px;
        line-height: 30px;
      }
      h3 {
        max-width: 280px;
        font-size: 25px;
        line-height: 30px;
      }
    }
    .successfully {
      height: 80px;

    }
    .first::before {
      content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 0px;
      bottom: 0px;
      height: 237px;
      width: 123px;
    }
    .two::before {
      content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      right: 0px;
      top: 125px;
      height: 172px;
      width: 65px;
    }
  }
}
