.container {
  position: relative;
  margin: auto;
  color: #fff;
  background:#262626;
  
  height: 100vh;
}
.content {
  position: relative;
  max-width: 1428px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background-position: bottom;
  background-repeat: no-repeat;
}
.section {
  padding-top: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .image {
    background-image: url('../../assets/images/intro/image2.jpg');
    background-position: center;
    background-size: 100% 100%;
    position: relative;
    display: block;
    left: 0;
    top: 0;
    height: 468px;
    width: 855px;
    border-radius: 25px;
  }
  .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: -250px;
    top: 100px;
    height: 327px;
    width: 327px;
  }
  .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: -300px;
    top: 125px;
    height: 450px;
    width: 450px;
  }
  .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 120px;
    top: 555px;
    height: 87px;
    width: 87px;
  }
  .four::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 230px;
    top: 270px;
    height: 22px;
    width: 22px;
  }
  .five::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 230px;
    top: 250px;
    height: 22px;
    width: 22px;
  }
  .six::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 150px;
    top: 570px;
    height: 22px;
    width: 22px;
  }
  .seven::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 50px;
    top: 470px;
    height: 19px;
    width: 19px;
  }
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
    max-width: 980px;
  }
  h2 {
    text-align: center;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .button {
      margin: 10px;
      margin-bottom: 75px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 55px;
      border-radius: 16px;
      border: solid;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      color: #FFFFFF;
      background: unset;
      cursor: pointer;
    }
    .button:hover {
      transform: scale(1.025);
      background: linear-gradient(180deg, #323aa8 0%, #323aa5 100%);
      color: #fff;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .section {
    .image {
      width: 523px;
      height: 286px;
    }
    h1 {
      font-size: 33px;
      padding: 10px;
      line-height: 33px;
    }
    .first::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: 73px;
      width: 83px;
    }
    .two::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      right: 0;
      top: 350px;
      height: 272px;
      width: 83px;
    }
    .three::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 30px;
      top: 280px;
      height: 87px;
      width: 87px;
    }
    .four::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: unset;
      right: 100px;
      top: 200px;
      height: 22px;
      width: 22px;
    }
    .five::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      right: 80px;
      top: unset;
      bottom: 100px;
      height: 22px;
      width: 22px;
    }
    .six::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      right: unset;
      left: 60px;
      top: unset;
      bottom: 100px;
      height: 22px;
      width: 22px;
    }
    .seven::before {
      background-image: unset;
    }
  }
}
@media only screen and (max-width: 800px) {
  .section {
    .image {
      width: 380px;
      max-width: 100vw;
      height: 208px;
    }
    h1 {
      font-size: 25px;
    }
    .buttons {
      flex-direction: column;
      .button {
        margin: 5px;
      }
      margin-bottom: 50px;
    }
    .content img {
      max-width: calc(100vw - 50px);
      width: 300px;
      height: 200px;
    }
    .first::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: -20px;
      top: -20px;
      height: 73px;
      width: 83px;
    }
    .two::before {
      background-image: unset;

    }
    .three::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: unset;
      right: 0;
      top: 280px;
      height: 65px;
      width: 35px;
    }
    .four::before {
      content: " ";
            background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 20px;
      right: unset;
      top: 250px;
      height: 22px;
      width: 22px;
    }
    .five::before {
      background-image: unset;

    }
    .six::before {
      background-image: unset;

    }
    .seven::before {
      background-image: unset;
    }
  }
}
