.container {
  position: relative;
  margin: auto;
  color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  margin-top: 100px;
  margin-bottom: 50px;

  background:  #262626;
}

.content {
  position: relative;
  max-width: 1428px;
  margin: auto;
  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 71px;
    text-align: center;
    color: #262626;
  }
}
.section {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;

  margin-top: 50px;
  margin-bottom: 50px;

  .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: -110px;
    height: 113px;
    width: 197px;
  }
  .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: -10px;
    height: 113px;
    width: 197px;
  }
  .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 260px;
    top: 125px;
    height: 38px;
    width: 38px;
  }
  .four::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: -50px;
    top: 425px;
    height: 22px;
    width: 22px;
  }
  .five::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: -280px;
    bottom: -250px;
    height: 450px;
    width: 450px;
  }
  .six::before {
    content: " ";
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    bottom: -295px;
    height: 295px;
    width: 297px;
  }
  .seven::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 355px;
    top: 545px;
    height: 87px;
    width: 87px;
  }
  .row {
    display: flex;
    justify-content: center;
    align-content: center;
    .image {
        background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    top: 0;
    left: calc(50% - 46px);
        height: 87px;
    width: 87px;
    }
    .title {
      margin: 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 46px;
      line-height: 50px;
      color: #FFFFFF;
    }
    .description {
      margin: 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }
  .row:nth-child(1){
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 10px;
  }
  .animate{
    position:relative;
    margin: 10px;
    overflow:hidden;
    overflow-y:hidden;
    .item {
      color:white;
      text-align: center;
      .image {
        position: relative;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-content: center;
      }
      .title {
        position: relative;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }
      .description {
        position: relative;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #C6C6C6;
        
        height: calc(100% - 155px);

        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }
    }
  }
  .animate::after {
    content: " ";
    position: absolute;
    background: rgb(235, 7, 7);
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(-180deg, rgba(38,38,38,1) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0) 65%, rgba(38,38,38,1) 99%); 
  }
}
@media only screen and (max-width: 1200px) {
  .container {  
    background-image: unset;
    background:#262626;

  }
  .container::before {
    content: " ";
    background-image: unset;
  }
  .section {
    width: 100vw;

    margin-top: 50px;
    margin-bottom: 50px;
    margin-bottom: 100px;
    .first::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: 93px;
      width: 73px;
    }
    .two::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 110px;
      top: 30px;
      height: 22px;
      width: 22px;
    }
    .three::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: unset;
      right: 0;
      top: 0;
      height: 140px;
      width: 68px;
    }
    .five::before {
      background-image: unset;
      display: none;
    }
    .four::before {
      background-image: unset;
      display: none;
      
    }
    .six::before {
      background-image: unset;
      display: none;
    }
    .seven::before {
      background-image: unset;
      display: none;
    }
    .row {
      .title {
        font-size: 33px;
        text-align: center;
        line-height: 22px;
      }
      .description {
        font-size: 16px;
        text-align: center;
        line-height: 22px;
      }
      max-width: calc(100vw - 0px);
    
    }
    justify-content:left;
    flex-direction: column;
    .animate::after {
      content: " ";
      position: absolute;
      background: rgb(235, 7, 7);
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(38,38,38,1) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0) 65%, rgba(38,38,38,1) 99%); 
    }
  }
  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 44px;
    text-align: center;
    color: #262626;
  }
}
@media only screen and (max-width: 800px) {
  .container {
    margin-top: 0;
  }
  .section {
    .row {
      .title {
        font-size: 18px;
        text-align: center;
      }
      .description {
        margin-top: 20px;
        font-size: 14px;
      }
    }
    .first::before {
      background-image: unset;
    }
    .two::before {
      background-image: unset;
    }
    .three::before {
      background-image: unset;
    }
    .animate::after {
      content: " ";
      position: absolute;
      background: rgb(235, 7, 7);
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(38,38,38,1) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(38,38,38,1) 99%); 
    }
  }
  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    text-align: center;
    color: #262626;
  }
}
