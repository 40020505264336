.container {
  position: relative;
  margin: auto;
  color: #fff;
  background:#262626;
  margin-top: 100px;
}
.content {
  position: relative;
  max-width: 1428px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 900px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;

  color: #FFFFFF;

  .left {
    margin-top: -310px;
    position: absolute;
    margin-left: -600px;
    max-width: 100vw;

  }
  .center {
    background-image: url('../../assets/images/Save/arr.png');
    background-position: center;
    background-size: 100% 100%;

    margin-top: -220px;
    position: absolute;
    margin-left: -90px;

    width: 230px;
    height: 230px;
  }

  .right {
    margin-top: -50px;
    position: absolute;
    margin-left: 100px;
    max-width: 100vw;
  }

  .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: -250px;
    top: 100px;
    height: 327px;
    width: 327px;
  }
  .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: -300px;
    top: -160px;
    height: 456px;
    width: 456px;
  }
  .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 320px;
    top: 720px;
    height: 87px;
    width: 87px;
  }
  .four::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 230px;
    top: 270px;
    height: 22px;
    width: 22px;
  }
  .five::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 230px;
    top: 250px;
    height: 22px;
    width: 22px;
  }
  .six::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 150px;
    top: 570px;
    height: 22px;
    width: 22px;
  }
  .seven::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 50px;
    top: 470px;
    height: 19px;
    width: 19px;
  }
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 56px;
    text-align: center;
    color: #FFFFFF;
    max-width: 980px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    margin-top: 0;
  }
  .section {
    height: 570px;
    h1 {
      font-size: 33px;
      padding: 10px;
      line-height: 33px;
    }
    .left {
      margin-top: -300px;
      position: absolute;
      margin-left: -400px;
      width: 400px;
      transform: scale(0.6);
    }
    .center {
      margin-top: -170px;
      position: absolute;
      margin-left: -90px;
      transform: scale(0.6);
    }
    .right {
      margin-top: -100px;
      position: absolute;
      margin-left: 0px;
      transform: scale(0.6);
    }
    .first::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: 73px;
      width: 83px;
    }
    .two::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      right: 0px;
      top: 150px;
      height: 272px;
      width: 83px;
    }
    .three::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: -10px;
      top: 180px;
      height: 87px;
      width: 87px;
    }
    .four::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: unset;
      right: 100px;
      top: 200px;
      height: 22px;
      width: 22px;
    }
    .five::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      right: 80px;
      top: unset;
      bottom: 100px;
      height: 22px;
      width: 22px;
    }
    .six::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      right: unset;
      left: 60px;
      top: unset;
      bottom: 100px;
      height: 22px;
      width: 22px;
    }
    .seven::before {
      background-image: unset;
    }
  }
}
@media only screen and (max-width: 800px) {
  .content {
    background-image: unset;

  }
  .section {
    height: 770px;

    h1 {
      font-size: 25px;
    }

    .left {
      margin-top: -400px;
      margin-left: -210px;

    }
    .left > * {
      transform: scale(0.7);
    }
    .left div {
      transform: scale(0.7) translateY(-50px);
    }
    .center {
      background-image: url('../../assets/images/Save/arr2.png');
      margin-top: -50px;
      margin-left: -30px;

      width: 70px;
      height: 70px;
      
    }
    .right {
      margin-top: 00px;
      margin-left: -210px;

    }
    .right > * {
      transform: scale(0.7);
    }
    .right div {
      transform: scale(0.7) translateY(-50px);
    }

    .first::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: -20px;
      top: -20px;
      height: 73px;
      width: 83px;
    }
    .two::before {
      background-image: unset;

    }
    .three::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: unset;
      right: 0px;
      top: unset;
      bottom: 125px;
      height: 143px;
      width: 46px;
    }
    .four::before {
      content: " ";
      background-image: url('../../assets/images/Sound waves/bright.svg');
      background-position: center;
      background-size: 100% 100%;
      position: absolute;
      display: block;
      left: 50px;
      right: unset;
      top: 200px;
      height: 22px;
      width: 22px;
    }
    .five::before {
      background-image: unset;
    }
    .six::before {
      background-image: unset;
    }
    .seven::before {
      background-image: unset;
    }
  }
}
