.container {
  position: relative;
  margin: auto;
  color: #262626;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.content {
  position: relative;
  max-width: 1428px;
  margin: auto;

  display: flex;
  align-content: center;
  justify-content: center;

  flex-direction: column;

  padding-top: 55px;


  background-position: top;
  background-repeat: no-repeat;

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 71px;
    text-align: center;
    color: #262626;   
    
  }
}
.section {
  margin: 75px;
  margin-top: 75px;

  display: flex;
  align-items: center;
  justify-content:center;

  flex-direction: row;

  overflow: hidden;
  overflow-x: auto;

  margin-left: 0;
  margin-right: 0;

  .item {
    position: relative;

    margin: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 305px;
    min-width: 305px;
    height: 380px;

    border-radius: 25px;
    padding-top: 150px;

    color:white;
    text-align: center;

    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.2);

    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #262626;
      padding: 5px;
    }
    .content {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      text-align: center;
      color: rgb(75, 75, 75);   
      padding: 25px;
      background-image: unset;

    }
  }
  .item:nth-child(2){
    background-color: rgb(41, 41, 41);
    color: rgb(255, 255, 255);
    .title {
      color: rgb(255, 255, 255);
    }
    .content {
      color: #E0E0E0;
    }
  }
  .item:nth-child(1) .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: 125px;
    width: 100px;
  }
  .item:nth-child(2) .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    height: 125px;
    width: 100px;
  }
  .item:nth-child(3) .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: 125px;
    width: 100px;
  }
  .item:nth-child(4) .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    height: 125px;
    width: 100px;
  }
    .item:nth-child(5) .first::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    height: 125px;
    width: 100px;
  }
  .item:nth-child(1) .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 200px;
    top: 15px;
    height: 25px;
    width: 25px;
  }
  .item:nth-child(2) .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 200px;
    top: 15px;
    height: 25px;
    width: 25px;
  }
  .item:nth-child(3) .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 200px;
    top: 15px;
    height: 25px;
    width: 25px;
  }
  .item:nth-child(4) .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 200px;
    top: 15px;
    height: 25px;
    width: 25px;
  }
    .item:nth-child(5) .two::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 200px;
    top: 15px;
    height: 25px;
    width: 25px;
  }
  .item:nth-child(1) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(2) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/bright.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(3) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
  .item:nth-child(4) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
    .item:nth-child(5) .three::before {
    content: " ";
    background-image: url('../../assets/images/Sound waves/black.svg');
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    display: block;
    left: 0;
    top: 55px;
    height: 76px;
    width: 56px;
  }
}
@media only screen and (max-width: 1200px) {
  .section {
    justify-content:left;
    max-width: 100vw;
  }
  .content {
    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 33px;
      line-height: 44px;
      text-align: center;
      color: #262626;
    }
  }
}
@media only screen and (max-width: 800px) {
  .content {
    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
      text-align: center;
      color: #262626;
    }
  }
}
