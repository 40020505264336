.toTop {
  position: fixed;
  display: block;
  bottom: -100px;
  right: 20px;
  margin:0;
  padding: 0;
  font-size: 48px;
  color: rgb(39, 39, 39);
  cursor: default;
  color: rgb(255, 255, 255);
  transition: 500ms;
  background-color: rgba(0, 0, 0, 0.43);
  display: flex;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;

    transition: .2s ease-in-out,box-shadow .2s ease-in-out;
    cursor: pointer;
  }
  .toTop:hover {
    transform: scale(1.15);
  }
