.container {
  position: relative;
  margin: auto;
  color: #000000;
}
.content {
  position: relative;
  max-width: 1228px;
  margin: auto;
  min-height:  820px;
  margin-top: 50px;
  margin-bottom: 50px;
  h1 {
	  font-family: "Inter";
	  font-style: normal;
	  font-weight: 700;
	  font-size: 50px;
	  line-height: 71px;
	  text-align: center;
	  color: rgb(38, 38, 38);
	}
}
.title {
  position: relative;
  padding: 25px;
  
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: rgb(82, 82, 82);
}
.image {
    background-image: url('../../assets/images/Faq/bg.jpg');
    background-position: center;

      border-radius: 50px;
  width: 810px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  display: block;
  right: 0;
  top: 0;
}
.section {
  position: relative;
}
.tabs {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  height: 0;
}
.tabsBox { 
  position: relative;
  margin: 50px 0 0 50px;
  width: 660px;
  color: rgb(0, 0, 0);

  box-sizing: border-box;
  overflow: hidden;
  text-align: left;

  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(9.5px);
  border-radius: 25px;
  
  span {
    position: relative;
    margin: 10px;
    font-weight: bold;
    color: rgb(75, 75, 75);
    text-transform: uppercase;
    text-align: left;
  }
}
.tabsBoxItemData { 
  position: relative;
  margin-top: 10px;
}
.tabsBoxItem { 
  position: relative;
  margin: 10px;
  padding: 10px;
  display: flex;
  cursor: pointer;
}
.tabsBoxItemOpen { 
  position: relative;
  margin: 10px;
  margin-top: 50px;
  padding: 10px;
}
.tabsBoxItemDescription { 
  position: relative;
  padding: 10px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: rgb(0, 0, 0);
  overflow: auto;
  max-height: 140px;
}
.tabsBoxItemTitle { 
  position: relative;
  padding: 10px;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgb(0, 0, 0);

  box-shadow: rgb(0 0 0 / 50%) 0px -6px 6px -6px;
}
.tabsBoxItemImage { 
  position: relative;
  padding: 10px;
}
@media only screen and (max-width: 1200px) {
  .tabsBox { 
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(9.5px);
    border-radius: 25px;
  }
  .tabsBoxItemDescription { 
    color: rgb(0, 0, 0);
  }
}
@media only screen and (max-width: 600px) {
  .tabsBox { 
    width: calc(100vw - 50px);
    left: 25px;
    margin: 0;
    margin-top: 50px;
  }
  .tabsBoxItemTitle { 
    font-size: 16px;
    line-height: 16px;

  }
  .image {
border-radius: 0;
  }
}


@media only screen and (max-width: 1200px) {
  .section {
    margin-top: 100px;
  }
  .content {
    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 33px;
      line-height: 44px;
      text-align: center;
      color: #262626;
    }
  }
}
@media only screen and (max-width: 800px) {
  .content {
    h1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 33px;
      text-align: center;
      color: #262626;
    }
  }
}
